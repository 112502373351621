.App {
  text-align: center;
  background-color: #565656;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.App form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
}

.App form * {
  margin: 0.2rem;
}

.card {
  border: 1px solid grey;
  border-radius: 1rem;
  padding: 1rem;
  box-shadow: 0 1px 10px #000000;
  background-color: #70798c;
  margin-bottom: 10px;
}

button {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.container {
  display: flex;
  align-items: center;
  /* Vertically centers the items */
  justify-content: flex-start;
  /* Aligns items to the start of the container */
  gap: 10px;
  /* Adds space between the elements */
}

.container input {
  flex: 1;
  /* Makes the input take up remaining space */
  padding: 5px;
  background-color: rgb(233, 233, 233);
}

/* .container img {
  /* height: 30px; 
  width: auto; 
} */


@media screen and (orientation: portrait) {
  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* Vertically centers the items */
    justify-content: flex-start;
    /* Aligns items to the start of the container */
  }

  .container input {
    flex: 1;
    /* Makes the input take up remaining space */

  }
}

/* Apply styles when the screen is in landscape orientation */
@media screen and (orientation: landscape) {
  #asdf {
    border: 2px solid green;
    /* Apply a green border in landscape orientation */
  }
}

.verticalAlign img {
  /* vertical-align: middle; */
}