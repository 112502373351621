.loading {
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    animation: blink 1s infinite;
    margin-left: 10px;
    grid-column-start: 1;
    grid-column-end: 3;
}

@keyframes infinite-rotate {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

.loading {
    animation: infinite-rotate 2s linear infinite;
    width: 1rem;
    height: 1rem;
}

img {
    margin: 0px;
    padding: 0px;
    border: 0px solid red;
}