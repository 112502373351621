table.train {
    border-spacing: 0;
    border-collapse: collapse;
    border-bottom: 2px solid rgb(50, 50, 50);

}

table.train td {
    border-bottom: 1px solid rgb(50, 50, 50);
    padding-left: 0.5rem;
    text-align: left;
}

table.train th {
    border-top: 2px solid rgb(50, 50, 50);
    border-bottom: 2px solid rgb(50, 50, 50);
    text-align: left;
    padding-left: 0.5rem;
    /* 
    vertical-align: bottom; */
}

table.train img {
    vertical-align: middle;
    padding-left: 0.2rem;
}